import React from 'react';

import { BackgroundWrapper, H1, Regular } from '../components';

const Success: React.FC = React.memo(() => (
  <BackgroundWrapper>
    <H1 margin="4rem 0 1rem 0">Payment successful!</H1>
    <Regular textAlign="center">
      Within 24 hours we will send you an email with your personalized meal plan
    </Regular>
  </BackgroundWrapper>
));

Success.displayName = 'Success';

export default Success;
